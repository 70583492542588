@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    /*font-size: 12px;*/
    background-color: #eee;
    /* https://systemfontstack.com */
    font-family: -apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'segoe ui', 'helvetica neue', helvetica, Ubuntu, roboto, noto, arial, sans-serif;
}

#__next, html, body {
    overflow-x: auto;
}

#__next {
    min-width: 1100px;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    height: 100%;
}

*, *:focus, *:hover, *:active {
    outline: none !important;
}

:focus {
    outline: none !important;
}

.padding-1rem {
    padding: 1rem;
}

.margin-bottom-2rem {
    margin-bottom: 2rem;
}

.margin-right-1rem {
    margin-right: 1rem;
}

.tooltip::after {
    border-color: #334856 transparent transparent transparent;
}

.tooltip::before {
    background-color: #334856;
    color: white;
    left: 60%;
    max-width: 10rem;
}

@font-face {
    font-family: "Orbitron";
    font-display: block;
    /*noinspection CssUnknownTarget*/
    src: url("/fonts/Orbitron/static/Orbitron-Medium.ttf") format("truetype");
}

/*@font-face {*/
/*    font-family: 'FunctionProBook';*/
/*    !*noinspection CssUnknownTarget*!*/
/*    src: url('/fonts/FunctionPro/functionpro-book.otf') format('opentype');*/
/*    font-weight: normal;*/
/*    font-style: normal;*/
/*}*/

/*@font-face {*/
/*    font-family: 'AvenirNext';*/
/*    !*noinspection CssUnknownTarget*!*/
/*    src: url('/fonts/AvenirNext/AvenirNextCyr-Regular.ttf') format('truetype');*/
/*    font-weight: normal;*/
/*    font-style: normal;*/
/*}*/

/*body {*/
/*    font-family: Orbitron;*/
/*}*/
